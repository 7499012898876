<script lang="ts" setup>
import type { ProductType } from '~/api/common';

const props = defineProps<{
  product: ProductType;
  dataTestId?: string;
}>();

const { t } = useI18n();
const productCartStore = useProductCartStore();

const quantity = computed(
  () =>
    productCartStore.cartData.find(
      (item) => item.skuId === props.product.addToBasketId,
    )?.quantity || 0,
);
const selectedPrice = computed(
  () => props.product && priceFormat(props.product.priceInfo.price),
);
const totalPrice = computed(
  () =>
    props.product &&
    priceFormat(props.product.priceInfo.price * quantity.value),
);
const totalBasePrice = computed(
  () =>
    props.product.priceInfo.basePrice &&
    quantity.value &&
    priceFormat(props.product.priceInfo.basePrice * quantity.value),
);

const changeQuantity = (count: number) => {
  if (props.product.addToBasketId) {
    productCartStore.updateCartItemByProductInfo(props.product, count, true);
  }
};
</script>

<template>
  <div class="productCardActions" :data-test-id="dataTestId">
    <button
      @click="changeQuantity(1)"
      type="button"
      class="productCardActions__button productCardActions__button_main"
      :data-test-id="dataTestId && `${dataTestId}-append`"
    >
      <Transition name="reel">
        <span
          class="productCardActions__text_state"
          v-if="product.addToBasketId && quantity"
        >
          {{ t('current', { quantity, price: totalPrice }) }}
          <span v-if="totalBasePrice" class="productCardActions__text_prev">
            {{ totalBasePrice }}
          </span>
        </span>

        <i18n-t
          v-else-if="product.addToBasketId"
          keypath="add"
          tag="span"
          class="productCardActions__text_state"
        >
          <template #mobile>
            <span class="productCardActions__text_noMobile">
              {{ t('mobile_add') }}
            </span>
          </template>
          <template #price>
            {{ selectedPrice }}
            <span v-if="totalBasePrice" class="productCardActions__text_prev">
              {{ totalBasePrice }}
            </span>
          </template>
        </i18n-t>
      </Transition>
    </button>
    <Transition name="productCardActions__iconButton">
      <button
        v-if="product.addToBasketId && quantity"
        @click="changeQuantity(-1)"
        class="productCardActions__button productCardActions__button_icon productCardActions__button_left"
      >
        <SvgoSimpleMinus class="productCardActions__icon" />
      </button>
    </Transition>
    <Transition name="productCardActions__iconButton">
      <button
        v-if="product.addToBasketId && quantity"
        @click="changeQuantity(1)"
        class="productCardActions__button productCardActions__button_icon productCardActions__button_right"
      >
        <SvgoSimplePlus class="productCardActions__icon" />
      </button>
    </Transition>
  </div>
</template>

<i18n>
ru:
  current: '{quantity} в корзине — {price}'
  add: Добавить {mobile} — {price}
  mobile_add:  в корзину 

en:
  current: '{quantity} in cart — {price}'
  add: Add {mobile} — {price}
  mobile_add:  to cart 
</i18n>

<style lang="scss">
.productCardActions {
  $self: &;

  --button-height: 56px;

  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: var(--button-height);
  border-radius: 30px;
  outline: none;
  cursor: pointer;
  transition:
    background-color 0.3s ease,
    color 0.3s ease;
  color: #fff;
  border: 1px solid #121212;
  background-color: #121212;

  &:hover {
    background-color: #fff;
    color: #000;
  }

  &__button {
    position: relative;
    display: flex;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    padding: 0 40px;
    gap: 10px;
    height: 56px;
    font-size: 16px;
    border: none;
    cursor: pointer;
    transition:
      background-color 0.3s ease,
      color 0.3s ease;
    color: inherit;
    overflow: hidden;

    @include mq('sm') {
      font-size: 16px;
    }

    &_main {
      flex: 1;
      padding: 0 8px;
      width: 100%;

      @include mq('sm') {
        padding: 0 4px;
      }
    }

    &_icon {
      position: absolute;
      background: none;
      border: none;
      padding: 0;
      outline: none;
      font-size: 18px;
      letter-spacing: 0.2px;
      cursor: pointer;
      transition: opacity 0.3s ease;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;

      @include mq('xs') {
        display: none;
      }

      &:hover {
        opacity: 0.3;
      }
    }

    &_left {
      left: 0;
      padding-left: 17px;
      padding-right: 6px;
    }

    &_right {
      right: 0;
      padding-right: 17px;
      padding-left: 6px;
    }

    &:disabled {
      pointer-events: none;
      color: #878787;
      background-color: #e6e6e6;
      border-color: #e6e6e6;
    }
  }

  &__text {
    &_state {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      position: absolute;
    }

    &_prev {
      margin-left: 10px;
      opacity: 0.4;
      text-decoration-line: line-through;

      @include mq('sm') {
        display: none;
      }
    }

    &_noMobile {
      @include mq('sm') {
        display: none;
      }
    }
  }

  &__icon {
    margin-bottom: 0;
    display: block;
    width: 16px;
    height: 16px;

    @include mq('sm') {
      width: 16px;
      height: 16px;
    }
  }

  &__iconButton {
    &-enter-active,
    &-leave-active {
      transition: opacity 0.3s ease;
    }

    &-enter-from,
    &-leave-to {
      opacity: 0;
    }
  }
}
</style>
