<script lang="ts" setup>
import type { ProductType } from '~/api/common';
import placeholder from '~/assets/icons/placeholder.svg?url';

const props = defineProps<{
  product: ProductType;
  dataTestId?: string;
}>();

const { t } = useI18n();

const stringPrice = computed(() => priceFormat(props.product.priceInfo.price));
const stringPrevPrice = computed(
  () =>
    props.product.priceInfo.basePrice &&
    priceFormat(props.product.priceInfo.basePrice),
);
const saleBadge = computed(
  () =>
    props.product.priceInfo.basePrice &&
    Math.round(
      (1 - props.product.priceInfo.price / props.product.priceInfo.basePrice) *
        100,
    ),
);
</script>

<template>
  <div class="productCard" :data-test-id="dataTestId">
    <div
      class="productCard__container"
      :class="{
        productCard__container_notAvailable: !product.addToBasketId,
      }"
    >
      <NuxtLink :to="`/product/${product.code}`">
        <!-- TODO: Нужен вот этот PR: https://github.com/nuxt/image/pull/676 -->
        <!-- :sizes="IMAGE_SIZES.gridCard" -->
        <NuxtImg
          format="webp"
          :src="product.picture || placeholder"
          :alt="product.altPicture || product.name"
          width="800"
          :placeholder="placeholder"
          class="productCard__image"
        />

        <span
          class="productCard__notAvailableText"
          v-if="!product.addToBasketId"
        >
          {{ t('no_stock') }}
        </span>
      </NuxtLink>

      <div class="productCard__actions" v-if="product.addToBasketId">
        <ProductCardActions
          :product="product"
          :data-test-id="dataTestId && `${dataTestId}-actions`"
        />
      </div>
    </div>

    <NuxtLink :to="`/product/${product.code}`">
      <p class="productCard__name">
        {{ product.name }}
      </p>
      <span v-if="product.volume" class="productCard__value">
        {{ product.volume }}
      </span>
      <span class="productCard__price">
        {{ stringPrice }}

        <span v-if="stringPrevPrice" class="productCard__price_prev">
          {{ stringPrevPrice }}
        </span>
      </span>
    </NuxtLink>

    <!-- TODO: А нужно ли еще -->
    <!-- <span-->
    <!--   v-if="!'product.isNew'"-->
    <!--   class="productCard__badge productCard__badge_new"-->
    <!-- >-->
    <!--   {{ 'product.isNew' && 'New' }}-->
    <!-- </span>-->
    <span v-if="saleBadge" class="productCard__badge productCard__badge_sale">
      {{ t('sale_badge', { value: saleBadge }) }}
    </span>
  </div>
</template>

<i18n>
ru:
  no_stock: Нет в наличии
  sale_badge: '{value}%'

en:
  no_stock: Not available
  sale_badge: '{value}%'
</i18n>

<style lang="scss">
.productCard {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.2px;
  color: #121212;
  position: relative;
  display: block;

  @include mq('sm') {
    font-size: 14px;
    line-height: 20px;
  }

  a {
    display: block;
    color: inherit;
  }

  &__container {
    position: relative;
    overflow: hidden;

    &_notAvailable {
      opacity: 0.5;
    }
  }

  &__notAvailableText {
    display: flex;
    width: 140px;
    min-width: fit-content;
    height: 37px;
    padding: 4px 6px;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 16px;
    top: 18px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(2.5px);

    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.25px;
    text-transform: uppercase;

    @include mq('sm') {
      left: 10px;
      top: 11px;
      width: 78px;
      height: 20px;
      font-size: 8px;
    }
  }

  &__badge {
    width: 52px;
    height: 52px;
    border-radius: 50%;
    color: #fff;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    pointer-events: none;

    &_new {
      background-color: #121212;
    }

    &_sale {
      background-color: #8ab844;
    }
  }

  &__image {
    aspect-ratio: 338 / 444;
    object-fit: cover;
    display: block;
    width: 100%;
    word-break: break-word;
  }

  &__name {
    padding-top: 12px;
    margin: 0;
  }

  &__value {
    display: block;
    padding-top: 2px;
  }

  &__price {
    display: flex;
    padding-top: 2px;
    gap: 25px;

    @include mq('sm') {
      gap: 15px;
    }

    &_prev {
      opacity: 0.4;
      text-decoration-line: line-through;
    }
  }

  &__actions {
    position: absolute;
    transform: translateY(100%);
    padding: 19px 14px 17px;
    transition: transform 0.5s ease;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #f2f2f2;

    @include mq('sm') {
      padding: 12px;
    }
  }

  @media (hover: hover) {
    &:hover &__actions {
      transform: translateY(0);
    }
  }

  &__desktopText {
    display: inline-block;

    @include mq('sm') {
      display: none;
    }
  }
}
</style>
